<script setup lang="ts">
  const SectionHeader = defineAsyncComponent(() => import('~/pages/home/components/SectionHeader.vue'));
  const SectionPremiers = defineAsyncComponent(() => import('~/pages/home/components/SectionPremiers.vue'));
  const SectionPopular = defineAsyncComponent(() => import('~/pages/home/components/SectionPopular.vue'));
  const SectionFilms = defineAsyncComponent(() => import('~/pages/home/components/SectionFilms.vue'));
  const SectionSummarize = defineAsyncComponent(() => import('~/pages/home/components/SectionSummarize.vue'));
  const SectionSeries = defineAsyncComponent(() => import('~/pages/home/components/SectionSeries.vue'));
  const SectionUpcoming = defineAsyncComponent(() => import('~/pages/home/components/SectionUpcoming.vue'));
  const SectionCollections = defineAsyncComponent(() => import('~/pages/home/components/SectionCollections.vue'));
  const SectionContinue = defineAsyncComponent(() => import('~/pages/home/components/SectionContinue.vue'));
  const SectionGenres = defineAsyncComponent(() => import('~/pages/home/components/SectionGenres.vue'));
  const SectionAdvantages = defineAsyncComponent(() => import('~/pages/home/components/SectionAdvantages.vue'));
  const SectionSubscriptions = defineAsyncComponent(() => import('~/pages/home/components/SectionSubscriptions.vue'));
  const SectionTopMovies = defineAsyncComponent(() => import('~/pages/home/components/SectionTopMovies.vue'));

  const { state, subscriptionActive } = storeToRefs(useAuthStore());
  const url = useRequestURL();

  useSeoMeta({
    title: 'Онлайн-кинотеатр FLEX - сериалы, фильмы, мультфильмы',
    description:
      'Смотреть онлайн или в дороге зарубежные фильмы, сериалы и мультфильмы в FullHD качестве и профессиональной озвучке TVShows эксклюзивно для FLEX без рекламы. Приложение FLEX доступно в App Store и Google Play',
    ogTitle: 'Онлайн-кинотеатр FLEX - сериалы, фильмы, мультфильмы',
    ogDescription:
      'Смотреть онлайн или в дороге зарубежные фильмы, сериалы и мультфильмы в FullHD качестве и профессиональной озвучке TVShows эксклюзивно для FLEX без рекламы. Приложение FLEX доступно в App Store и Google Play',
    ogImage: '/images/Flex_1024x500.webp',
    ogUrl: url.href,
    twitterTitle: 'Онлайн-кинотеатр FLEX - сериалы, фильмы, мультфильмы',
    twitterDescription:
      'Смотреть онлайн или в дороге зарубежные фильмы, сериалы и мультфильмы в FullHD качестве и профессиональной озвучке TVShows эксклюзивно для FLEX без рекламы. Приложение FLEX доступно в App Store и Google Play',
    twitterImage: '',
    twitterCard: 'summary',
  });
</script>

<template>
  <h1 class="hidden-title">
    FLEX – онлайн-кинотеатр с лучшими фильмами и сериалами планеты в отличном качестве и профессиональном озвучивании!
  </h1>
  <section-header />
  <section-continue v-if="state.loggedIn" />
  <section-advantages v-if="!subscriptionActive" />
  <section-premiers />
  <!--  <section-top-movies />-->
  <section-upcoming />
  <section-popular />
  <!--  <section-genres />-->
  <section-subscriptions v-if="!subscriptionActive" />
  <section-films />
  <section-series />
  <section-collections />
  <!--  <section-summarize />-->
</template>
